import { render, staticRenderFns } from "./huzhao.vue?vue&type=template&id=23b84de1&scoped=true&"
import script from "./huzhao.vue?vue&type=script&lang=js&"
export * from "./huzhao.vue?vue&type=script&lang=js&"
import style0 from "./huzhao.vue?vue&type=style&index=0&id=23b84de1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b84de1",
  null
  
)

export default component.exports